import { api } from '@/main'
import { defineStore } from 'pinia'

import { useUsersStore } from '@/stores/users/users'

import { AiProcedure } from '@/api/procedures'
import { AiDevResolution } from '@/api/dev_resolution'

import { buildDateFilters } from './procedureLibrary.helpers'

interface ExtendedProcedure extends AiProcedure {
  isAuditable: boolean,
}

interface ProcedureLibraryStoreState {
  areProceduresLoading: boolean,

  query: {
    procedureName: string,
  },

  procedures: AiProcedure[],
  devResolutions: AiDevResolution[],
}

const usersStore = useUsersStore()

export const useProcedureLibraryStore = defineStore('procedureLibraryStore', {
  state: (): ProcedureLibraryStoreState => ({
    query: {
      procedureName: '',
    },

    areProceduresLoading: true,

    procedures: [],
    devResolutions: [],
  }),

  getters: {
    proceduresList: (state) => {
      const procedures = state.procedures.map((procedure) => ({
        ...procedure,
        isAuditable: state.devResolutions.some(devResolution => devResolution.procedure === procedure.name),
      })) as ExtendedProcedure[]

      if (state.query.procedureName) {
        const filteredProcedures = procedures.filter((procedure) => {
          const { procedureName } = state.query

          const lowercasedProcedureName = procedureName.toLowerCase()
          const lowercasedName = (procedure.name as string).toLowerCase()
          const lowercasedFriendlyName = (procedure.friendlyName as string).toLowerCase()

          return lowercasedName.includes(lowercasedProcedureName) || lowercasedFriendlyName.includes(lowercasedProcedureName)
        })

        return filteredProcedures
      }

      return procedures
    },
  },

  actions: {
    setProcedureNameQuery(payload: string) {
      this.query.procedureName = payload.trim()
    },

    async fetchProcedures() {
      this.areProceduresLoading = true
      if (!usersStore.userGroup) return

      try {
        const { start, end } = buildDateFilters()

        const { data: { devResolutions } } = await api.devResolutions.devResolutionsListDevResolutions({
          pageSize: -1,
          filter: JSON.stringify({
            $and: [{
              manufacturer: { $in: usersStore.userGroup },
            }, {
              'created.seconds': { $gte: start },
            }, {
              'created.seconds': { $lte: end },
            }],
          }),
        })

        this.devResolutions = devResolutions as AiDevResolution[]

        const { data: { procedures } } = await api.procedures.proceduresListProcedures({
          pageSize: -1,
          readMask: 'name,friendlyName,modelStatus',
          filter: JSON.stringify({
            $and: [{
              manufacturer: { $in: usersStore.userGroup },
            }],
          }),
        })

        this.procedures = procedures as AiProcedure[]
      } catch (error) {
        console.error(error)
        this.procedures = []
      } finally {
        this.areProceduresLoading = false
      }
    },
  },
})
